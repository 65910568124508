
// Libraries
import * as React from 'react'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import InputFactory from '../../inputFactory'
import Button from '../../button'
import {faSearch, faPlus, faCogs} from '@fortawesome/pro-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class AdminBlogTableHeader extends React.Component {

	render() {
		const {titleIcon, titleText, infoText, searchValue, searchHandler, handleConfirmBuild} = this.props

		return <div className="admin-table__header">
			<div className="column column--title">
				<h1><FontAwesomeIcon icon={titleIcon} /> {titleText}</h1>
				{(infoText && infoText !== '') && <p>{infoText}</p>}
			</div>
			<div className="column column--search">
				<form>
					<FontAwesomeIcon icon={faSearch} className="search-icon" />
					<InputFactory
						type="search"
						name="search"
						placeholder="Search"
						value={searchValue}
						onChange={searchHandler}
						onKeyDown={this.handleOnKeyDown}
					/>
				</form>

				<Button to="/admin/blog/new" iconOnly data-tip data-for="tooltip-new">
					<FontAwesomeIcon icon={faPlus} title="New blog icon" />
					<span className="sr-only">New Blog</span>
					<ReactTooltip id="tooltip-new" place="top" backgroundColor="#005EB8" textColor="#fff">
						<span>New Blog</span>
					</ReactTooltip>
				</Button>

				<Button onClick={handleConfirmBuild} iconOnly data-tip data-for="tooltip-build">
					<FontAwesomeIcon icon={faCogs} title="New blog icon" />
					<span className="sr-only">Trigger Build</span>
					<ReactTooltip id="tooltip-build" place="top" backgroundColor="#005EB8" textColor="#fff">
						<span>Trigger Build</span>
					</ReactTooltip>
				</Button>
			</div>
		</div>
	}

	handleOnKeyDown = (keyDownEvent) => {
		if(keyDownEvent.keyCode === 13) {
			keyDownEvent.preventDefault();
			return false;
		}
	}
}

export default AdminBlogTableHeader
