
// Libraries
import * as React from 'react'
import moment from 'moment'
import Classnames from 'classnames'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminBlogTableRow extends React.Component {

	static contextType = NotificationContext

	render() {
		const {item, handleOpenView} = this.props
		let classNames = Classnames([
			'admin-table__row',
			{
				'admin-table__row--draft': item.status_id === 1,
			},
			{
				'admin-table__row--published': item.status_id === 2,
			},
		])

		return <tr className={classNames}>
			<td className="admin-table__cell--title">{item.title}</td>
			<td className="admin-table__cell--publish-at">{item.publish_at
				? moment(item.publish_at).format('DD/MM/YY')
				: '-'}</td>
			<td className="admin-table__cell--actions">
				<button onClick={() => handleOpenView(item.id)}>
					<FontAwesomeIcon icon={faArrowRight} title="Edit icon" /><span className="sr-only"> View/Edit</span>
				</button>
			</td>
		</tr>
	}
}

export default AdminBlogTableRow
