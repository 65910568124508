
// Libraries
import * as React from 'react'

// Components
import Modal from '../../modal'
import Button from '../../button'

class AdminBlogConfirmDraft extends React.Component {
	state = {
		isConfirming: false
	}

	render() {
		const {handleClose} = this.props
		const {isConfirming} = this.state

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Draft Blog Post?</h2>
				<p>You've set the publish date to a future date, clicking 'Yes' will draft this event.
					<br/><br/><b>Attention: Proceed and draft this blog post?</b></p>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Cancel</Button></li>
					<li><Button onClick={this.handleConfirm} isLoading={isConfirming} colorEndeavour>Yes</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleConfirm = () => {
		const {handleConfirm} = this.props
		handleConfirm()
	}
}

export default AdminBlogConfirmDraft
