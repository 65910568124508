
// Libraries
import * as React from 'react'
import _ from 'lodash'
import qs from 'qs'

// Services
import AppService from '../../../services/app'
import BlogService from '../../../services/blog'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminBlogTableHeader from '../../../components/admin/blog/adminBlogTableHeader'
import AdminBlogTable from '../../../components/admin/blog/adminBlogTable'
import AdminBlogView from '../../../components/admin/blog/adminBlogView'
import AdminBlogEdit from '../../../components/admin/blog/adminBlogEdit'
import AdminBlogConfirmDelete from '../../../components/admin/blog/adminBlogConfirmDelete'
import AdminBlogConfirmBuild from '../../../components/admin/blog/adminBlogConfirmBuild'
import Pagination from '../../../components/pagination'
import {faNewspaper} from '@fortawesome/pro-solid-svg-icons'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminBlogIndexPage extends React.Component {
	state = {
		activeFilters: {
			'filter': {}
		},
		items: [],
		pagination: [],
		emptyMessage: 'Retrieving blog posts ...',
		selectedItem: {},
		previewContent: null,
		modalViewShow: false,
		modalEditShow: false,
		modalConfirmDeleteShow: false,
		modalConfirmBuildShow: false,
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, items, selectedItem, pagination, emptyMessage, modalViewShow,
			modalEditShow, modalConfirmDeleteShow, modalConfirmBuildShow} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table page--admin-table-blog nav-blue-half">
				<Seo title="Blog Management" />
				<div className="admin-table__wrap">
					<AdminBlogTableHeader
						titleText="Blog Management"
						titleIcon={faNewspaper}
						searchValue={activeFilters.search}
						searchHandler={_.debounce(this.handleSearchChange, 500)}
						handleConfirmBuild={this.handleConfirmBuild} />

					<p className='filters-status'>
						Filter by:
						<a href="/admin/blog/" className={activeFilters?.filter?.status_id === '1' && 'active'}>Draft</a>
						<a href="/admin/blog/?filter[status_id]=2" className={activeFilters?.filter?.status_id === '2' && 'active'}>Published</a>
					</p>

					<Pagination pagination={pagination} handlePaginationClick={this.handlePaginationClick} />

					<AdminBlogTable
						activeFilters={activeFilters}
						items={items}
						getBlogs={this.getItems}
						emptyMessage={emptyMessage}
						handleOpenView={this.handleOpenView} />

					{modalViewShow && <AdminBlogView
						item={selectedItem}
						handleClose={() => this.setState({modalViewShow: false})}
						handleOpenEdit={this.handleOpenEdit}
						handleOpenPreview={this.handleOpenPreview}
						handleConfirmDelete={this.handleConfirmDelete} />}

					{modalEditShow && <AdminBlogEdit
						item={selectedItem}
						handleClose={() => this.setState({
							modalEditShow: false,
							modalViewShow: true
						})}
						handleEditComplete={this.handleEditComplete} />}

					{modalConfirmDeleteShow && <AdminBlogConfirmDelete
						item={selectedItem}
						handleClose={() => this.setState({modalConfirmDeleteShow: false})}
						handleConfirm={this.handleProcessDelete} />}

					{modalConfirmBuildShow && <AdminBlogConfirmBuild
						handleClose={() => this.setState({modalConfirmBuildShow: false})}
						handleConfirm={this.handleProcessBuild} />}
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

		Promise.all([
			this.getItems({...activeFilters, ...searchParams}, false)
				.catch(() => addNotification('There was an error fetching the blog posts.', 'error'))
		]).then(() => {
			this.setState({
				activeFilters: {...activeFilters, ...searchParams},
				emptyMessage: "No blog posts found, try adjusting your filters"
			})
		})
	}

	getItems = (queryParams) => {
		const blogService = new BlogService()
		queryParams = {...this.state.activeFilters, ...queryParams}
		queryParams['paginate'] = 1
		if (!queryParams['filter']['status_id']) {
			queryParams['filter']['status_id'] = "1"
		}

		return blogService.list(queryParams).then( (response) => {
			this.setState({
				items: response.data.data,
				pagination: response.data,
				activeFilters: queryParams
			})
		}).catch(err => console.error(err))
	}

	handleSearchChange = (searchValue) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		if (searchValue) {
			activeFilters['search'] = searchValue
		} else if (activeFilters['search']) {
			delete activeFilters['search']
		}

		if (activeFilters['page']) {
			delete activeFilters['page']
		}

		this.setState({activeFilters}, () => {
			this.getItems(activeFilters)
				.catch(() => addNotification('There was an error filtering the blog posts.', 'error'))
		})
	}

	handlePaginationClick = (page) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		activeFilters['page'] = page

		this.setState({activeFilters}, () => {
			this.getItems(activeFilters)
				.catch(() => addNotification('There was an error filtering the blog posts.', 'error'))
		})
	}

	handleOpenView = (itemId) => {
		const blogService = new BlogService()
		return blogService.get(itemId)
			.then( (response) => {
				this.updateStateSelectedItem(response.data)
				this.setState({
					modalViewShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleOpenEdit = (itemId) => {
		const blogService = new BlogService()
		return blogService.get(itemId)
			.then( (response) => {
				this.updateStateSelectedItem(response.data)
				this.setState({
					modalEditShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleEditComplete = (item) => {
		this.updateStateSelectedItem(item)
		this.updateStateItems(item)
		this.setState({
			modalEditShow: false,
			modalViewShow: true
		})
	}

	handleConfirmDelete = () => {
		this.setState({
			modalConfirmDeleteShow: true
		})
	}

	handleConfirmBuild = () => {
		this.setState({
			modalConfirmBuildShow: true
		})
	}

	handleProcessDelete = () => {
		const {selectedItem} = this.state
		const {addNotification} = this.context

		const blogService = new BlogService()
		return blogService.delete(selectedItem.id)
			.then( (response) => {
				if (response.data?.deleted) {
					addNotification('Blog post deleted', 'success')

					this.updateStateItems(selectedItem, true)
					this.setState({
						modalViewShow: false,
						modalConfirmDeleteShow: false
					})
				} else {
					addNotification('Unable to delete blog post', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	handleProcessBuild = () => {
		const {addNotification} = this.context

		const appService = new AppService()
		return appService.triggerBuild()
			.then( (response) => {
				if (response.data?.success) {
					addNotification('Build triggered', 'success')

					this.setState({
						modalConfirmBuildShow: false
					})
				} else {
					addNotification('Unable to trigger a build', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	updateStateSelectedItem = (event) => {
		this.setState(prevState => ({
			selectedItem: {...prevState.selectedItem, ...event}
		}))
	}

	updateStateItems = (item, remove = false) => {
		const {items} = this.state

		// update items state
		items.forEach((existingItem, key) => {
			if (existingItem.id === item.id) {
				if (remove) {
					items.splice(key, 1)
				}
				else {
					items[key] = {...existingItem, ...item}
				}
			}
		})

		this.setState({items})
	}
}

export default AdminBlogIndexPage
