
// Libraries
import * as React from 'react'

// Components
import Modal from '../../modal'
import Button from '../../button'

class AdminBlogConfirmBuild extends React.Component {
	state = {
		cancelMessage: '',
		formValue: '',
		isConfirming: false
	}

	render() {
		const {handleClose} = this.props
		const {isConfirming} = this.state

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Trigger build?</h2>
				<p>A build is required to update the website and use the latest content.  This takes around 20minutes
					to complete.</p>
				<p><b>Please note:</b> this action is permanent and cannot be undone, but changes can be made and a
					new build started if needed.</p>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Back</Button></li>
					<li><Button onClick={this.handleConfirm} isLoading={isConfirming} colorEndeavour>Confirm</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleConfirm = async () => {
		const {handleConfirm} = this.props

		this.setState({
			isConfirming: true
		})

		handleConfirm().then(() => {
			this.setState({
				isConfirming: false
			})
		})
	}
}

export default AdminBlogConfirmBuild
