
// Libraries
import * as React from 'react'
import moment from 'moment'
import Classnames from 'classnames'

// Components
import Modal from '../../modal'
import AdminBlogViewRow from './adminBlogViewRow'
import Button from '../../button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faTrashAlt, faEye} from '@fortawesome/pro-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class AdminBlogView extends React.Component {
	render() {
		const {item, handleClose, handleOpenEdit, handleConfirmDelete} = this.props

		let classNames = Classnames([
			'admin-view',
			'admin-view--blog',
			{
				'admin-view--blog-draft': item.status_id === 1,
			},
			{
				'admin-view--blog-published': item.status_id === 2,
			},
		])

		return <Modal handleClose={handleClose} modalRight>
			<div className={classNames}>
				<div className="admin-view__header">
					<div className="column">
						<h3>{item.title}</h3>
					</div>
					<div className="column">
						<ul className="admin-view__header-nav">
							<li><Button onClick={() => handleOpenEdit(item.id)} iconOnly data-tip data-for="tooltip-edit">
								<FontAwesomeIcon icon={faEdit} title="Edit icon" />
								<span className="sr-only">Edit</span>
								<ReactTooltip id="tooltip-edit" place="top" backgroundColor="#005EB8" textColor="#fff">
									<span>Edit</span></ReactTooltip>
							</Button></li>
							<li><Button to={`/admin/blog/preview/${item.slug}`} target="_blank" iconOnly data-tip data-for="tooltip-preview">
								<FontAwesomeIcon icon={faEye} title="Preview icon" />
								<span className="sr-only">Preview</span>
								<ReactTooltip id="tooltip-preview" place="top" backgroundColor="#005EB8" textColor="#fff">
									<span>Preview</span></ReactTooltip>
							</Button></li>
							<li><Button onClick={() => handleConfirmDelete()} iconOnly danger data-tip data-for="tooltip-delete">
								<FontAwesomeIcon icon={faTrashAlt} title="Delete icon" />
								<span className="sr-only">Delete</span>
								<ReactTooltip id="tooltip-delete" place="top" backgroundColor="#005EB8" textColor="#fff">
									<span>Delete</span></ReactTooltip>
							</Button></li>
						</ul>
					</div>
				</div>
				<div className="admin-view__info">
					<p><b>Status</b> {item.status ? item.status.name : '-'}</p>
					<p><b>Publish Date</b> {item.publish_at
						? moment(item.publish_at).format('DD/MM/YY')
						: '-'}</p>
				</div>
				<div className="admin-view__view">
					<div className="admin-view__view-group">
						<AdminBlogViewRow label="Teaser Text" value={item.summary} />
						<AdminBlogViewRow label="Feature Image" value={item.image
							? <img src={item.image} className='feature-image' alt={item.title} />
							: '-'} stacked />
					</div>
				</div>
			</div>
		</Modal>
	}
}

export default AdminBlogView
