
// Libraries
import * as React from 'react'
import moment from 'moment'

// Services
import BlogService from '../../../services/blog'

// Context
import NotificationContext from '../../../contexts/notification'

// Components
import Modal from '../../modal'
import AdminBlogViewRow from './adminBlogViewRow'
import AdminBlogConfirmPublish from './adminBlogConfirmPublish'
import AdminBlogConfirmDraft from './adminBlogConfirmDraft'
import InputFactory from '../../inputFactory'
import Button from '../../button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class AdminBlogEdit extends React.Component {
	state = {
		initialValues: {},
		inputValues: {},
		inputErrors: {},
		isSubmitting: false,
		modalConfirmPublish: false,
		confirmedPublish: false,
		modalConfirmDraft: false,
		confirmedDraft: false,
	}

	static contextType = NotificationContext

	render() {
		const {item, handleClose} = this.props
		const {initialValues, inputValues, inputErrors, isSubmitting,
			modalConfirmPublish, modalConfirmDraft} = this.state

		return <Modal handleClose={handleClose} modalRight adminForm>
			<div className="admin-form admin-form--blog">
				<div className="admin-form__header">
					<div className="column">
						<h3>Edit: {item.title}</h3>
					</div>
					<div className="column">
					</div>
				</div>
				<div className="admin-form__form">
					<form onSubmit={this.handleSubmit}>
						<div className="admin-form__field-group">
							<AdminBlogViewRow
								label="Title*"
								value={<InputFactory
									type="text"
									name="title"
									value={inputValues?.title}
									error={inputErrors?.title}
									onChange={this.handleInputChange}
								/>} />
							<AdminBlogViewRow
								label="Teaser Text*"
								value={<InputFactory
									type="text"
									name="summary"
									value={inputValues?.summary}
									error={inputErrors?.summary}
									onChange={this.handleInputChange}
								/>} />
							<AdminBlogViewRow
								label="Content*"
								value={<InputFactory
									type="tinymce"
									name="content"
									initialValue={initialValues?.content}
									value={inputValues?.content}
									error={inputErrors?.content}
									onChange={this.handleInputChange}
									config="blog"
								/>} />
							<AdminBlogViewRow
								label={<>
									Publish Date* <FontAwesomeIcon
										icon={faInfoCircle}
										data-tip data-for='tooltip-date'
										className='tooltip-icon'
										multiline={true}
										html={true} />
									<ReactTooltip id='tooltip-date' place="top" backgroundColor="#005EB8" textColor="#fff">
										If date is today or earlier, post will be published,
										else it will be drafted & published on the date supplied
									</ReactTooltip>
								</>}
								value={<InputFactory
									type="date"
									name="publish_at_date"
									value={inputValues?.publish_at_date}
									error={inputErrors?.publish_at_date}
									onChange={this.handleInputChange}
								/>} />
							<AdminBlogViewRow
								label="Feature Image*"
								value={<InputFactory
									type="file"
									name="image"
									value={inputValues?.image}
									initialFilePreview={inputValues?.imagePreview || null}
									error={inputErrors?.image}
									onChange={this.handleInputChange}
								/>} />
						</div>

						<div className="admin-form__submit-wrap">
							<Button type="button" onClick={handleClose} colorEndeavour hollow>Discard Changes</Button>
							<Button type="submit" isLoading={isSubmitting} colorEndeavour>Save Changes</Button>
						</div>
					</form>
				</div>
			</div>

			{modalConfirmPublish && <AdminBlogConfirmPublish
				handleClose={() => this.setState({modalConfirmPublish: false})}
				handleConfirm={this.handleConfirmPublish} />}

			{modalConfirmDraft && <AdminBlogConfirmDraft
				handleClose={() => this.setState({modalConfirmDraft: false})}
				handleConfirm={this.handleConfirmDraft} />}
		</Modal>
	}

	componentDidMount() {
		const {item} = this.props
		const {initialValues, inputValues} = this.state
		const publish_at = moment(item.publish_at)

		initialValues.content = item.content

		inputValues.title = item.title
		inputValues.summary = item.summary
		inputValues.content = item.content
		inputValues.publish_at_date = publish_at.format('YYYY-MM-DD')
		inputValues.imagePreview = item.image

		this.setState({ inputValues })
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleConfirmPublish = () => {
		this.setState({
			modalConfirmPublish: false,
			confirmedPublish: true
		}, () => {
			this.handleSubmit()
		})
	}

	handleConfirmDraft = () => {
		this.setState({
			modalConfirmDraft: false,
			confirmedDraft: true
		}, () => {
			this.handleSubmit()
		})
	}

	handleSubmit = async (submitEvent = null) => {
		if (submitEvent) {
			submitEvent.preventDefault()
		}
		this.setState({
			isSubmitting: true
		})

		const {item, handleEditComplete} = this.props
		const {inputValues, confirmedPublish, confirmedDraft} = this.state
		const blogService = new BlogService()
		const {addNotification} = this.context

		const publish_at = moment(inputValues.publish_at_date)

		// confirm date change
		if (item.status_id !== 2 && publish_at <= moment() && !confirmedPublish) {
			this.setState({
				modalConfirmPublish: true,
				isSubmitting: false
			})
			return false
		} else {
			this.setState({
				confirmedPublish: false
			})
		}

		// confirm date change
		if (item.status_id !== 1 && publish_at > moment() && !confirmedDraft) {
			this.setState({
				modalConfirmDraft: true,
				isSubmitting: false
			})
			return false
		} else {
			this.setState({
				confirmedDraft: false
			})
		}

		const formData = blogService.formData(inputValues)
		await blogService.put(item.id, formData)
			.then(async (response) => {
				if (response.success) {
					addNotification('Blog Updated', 'success')
					handleEditComplete(response.data)
				}
				else {
					const inputErrors = response.errors
					this.setState({
						inputErrors: inputErrors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminBlogEdit
