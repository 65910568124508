
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import AdminBlogTableRow from './adminBlogTableRow'
import TableSort from '../tableSort'

class AdminBlogTable extends React.Component {
	render() {
		const {items, emptyMessage, handleOpenView, updateStateItems, getBlogs} = this.props

		let classNames = Classnames([
			'admin-table admin-table--blog wrap--scrollx',
		])

		return (
			<div className={classNames}>
				<table className="admin-table__table">
					<thead>
						<tr>
							<th>Article Title <TableSort fieldName="title" refreshData={getBlogs}/></th>
							<th>Publish Date <TableSort fieldName="publish_at" refreshData={getBlogs}/></th>
							<th className="actions"><span className="sr-only">Actions</span></th>
						</tr>
					</thead>
					<tbody>
					{
						items?.length
							? items.map((item) => <AdminBlogTableRow
								key={item.id}
								item={item}
								handleOpenView={handleOpenView}
								updateStateItems={updateStateItems} />)
							: <tr><td colSpan={100} align="center">{emptyMessage}</td></tr>
					}
					</tbody>
				</table>
			</div>
		)
	}
}

export default AdminBlogTable
